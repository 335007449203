<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
// filters
const filter_orders = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const orders = ref()
const customer_invoices = ref([])

onMounted(() => {
    get_orders()
})

const get_orders = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-all-bexio-orders")
        .then(response => {
            orders.value = response.data
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}
const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const bexio_order = (id) => {
    window.open('https://office.bexio.com/index.php/kb_order/show/id/' + String(id) + '#invoices','_blank')
}
const bexio_invoice = (id) => {
    window.open('https://office.bexio.com/index.php/kb_invoice/show/id/' + String(id), '_blank')
}

const open_url = (url) => {
    window.open(url, '_blank')
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2" style="width: 300px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_orders['global'].value" placeholder="Suche" />
                    </span>
                </template>
                <template #end>
                    <Button label="Bexio Statistiken"  @click="open_url('https://api.snpy.ch/providebit/get-bexio-metrics')" class="w-auto mr-1" type="button" icon="pi pi-file-excel" v-tooltip.left="'Bexio Metrics exportieren'" />
                </template>
            </Toolbar>
        </div>
        <DataTable v-model:expandedRows="customer_invoices" v-model:filters="filter_orders"  :value="orders" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true">
            <Column expander style="width: 5rem" />
            <Column field="id" header="Auftrag" sortable>
                <template #body="slotProps">
                    <Avatar icon="pi pi-file" class="bg-primary text-white mr-2" shape="circle" />
                    {{slotProps.data.id}} - {{slotProps.data.firstname}} {{slotProps.data.lastname}} ({{slotProps.data.customer_nr}})
                </template>
            </Column>
            <Column field="type" header="Typ" sortable>
                <template #body="slotProps">
                    <!--<Chip :class="slotProps.data.status == 'Weizen' ? 'bg-yellow-700 text-white' : 'bg-red-500 text-white'" :label="slotProps.data.type" />-->
                    <Chip v-if="slotProps.data.type == 'Weizen'" label="Weizen" class="bg-yellow-700 text-white" />
                    <Chip v-if="slotProps.data.type == 'Lebensmittel'" label="Lebensmittel" class="bg-purple-700 text-white" />
                    <Chip v-if="slotProps.data.type == 'Auslieferung'" label="Auslieferung" class="bg-pink-700 text-white" />
                </template>
            </Column>
            <Column field="status" header="Status" sortable>
                <template #body="slotProps">
                    <Chip v-if="slotProps.data.status == 5" :label="slotProps.data.status_text" class="bg-primary text-white" />
                    <Chip v-if="slotProps.data.status == 6" :label="slotProps.data.status_text" class="bg-green-700 text-white" />
                    <Chip v-if="slotProps.data.status == 15" :label="slotProps.data.status_text" class="bg-orange-600 text-white" />
                    <Chip v-if="slotProps.data.status == 21" :label="slotProps.data.status_text" class="bg-red-600 text-white" />
                </template>
            </Column>
            <Column header="Laufzeit" sortable>
                <template #body="slotProps">
                    {{format_swiss_date(slotProps.data.subscription_start)}} - {{format_swiss_date(slotProps.data.subscription_end)}}
                </template>
            </Column>
            <Column field="lastname" hidden></Column>
            <Column field="firstname" hidden></Column>
            <Column field="customer_nr" hidden></Column>
            <Column field="title" header="Betrag" sortable>
                <template #body="slotProps">
                    {{format_currency(slotProps.data.total)}}
                </template>
            </Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <Button label="Bexio" @click="bexio_order(slotProps.data.id)" class="mr-2 p-button w-auto" style="background-color: #0d2f3b; color: #bbdc00; border: 1px solid #bbdc00" icon="pi pi-eye" />
                </template>
            </Column>
            <template #expansion="slotProps">
                <div rounded class="overflow-hidden shadow-4 rounded border-200">
                    <DataTable :value="slotProps.data.invoices" responsiveLayout="scroll" :rowHover="true">
                        <Column field="document_nr" header="Rechnung" sortable>
                            <template #body="slotProps">
                                <Avatar icon="pi pi-file" class="bg-primary text-white mr-2" shape="circle" />
                                {{slotProps.data.document_nr}} - {{ slotProps.data.title }}
                            </template>
                        </Column> 
                        <Column field="date" header="Datum" sortable>
                            <template #body="slotProps">
                                {{format_swiss_date(slotProps.data.date)}}
                            </template>
                        </Column>
                        <Column field="total" header="Betrag" sortable>
                            <template #body="slotProps">
                                {{format_currency(slotProps.data.total)}}
                            </template>
                        </Column>
                        <Column field="status" header="Status" sortable>
                            <template #body="slotProps">
                                <Badge v-if="slotProps.data.status == 9" value="Bezahlt" class="mr-2 bg-green-600 text-white" />
                                <Badge v-if="slotProps.data.status == 8" value="Offen" class="mr-2 bg-primary-500 text-black" />
                                <Badge v-if="slotProps.data.status == 16" value="Teilweise" class="mr-2 bg-orange-500 text-white" />
                            </template>
                        </Column>
                        <Column field="id" header="">
                            <template #body="slotProps">
                                <Button label="Bexio" @click="bexio_invoice(slotProps.data.id)" class="mr-2 p-button w-auto" style="background-color: #0d2f3b; color: #bbdc00; border: 1px solid #bbdc00" icon="pi pi-eye" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
        </DataTable>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

</template>